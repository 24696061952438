import { http } from '../index';

export const getEvents = ({ query } = {}) => {
  const params = { prescriptionId: query };
  return http.get('/device-management/v1/events', { params }).then(({ data }) => data);
};

export const deleteEvent = ({ dockId, prescriptionId, timestamp }) => {
  const params = { dockId, blisterPackId: prescriptionId, timestamp };
  return http.delete('/device-management/v1/events', { params }).then(({ data }) => data);
};
