import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Button = ({ children, ...rest }) => <S.Button {...rest}>{children}</S.Button>;

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
