import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const Link = styled(RouterNavLink)`
  color: ${({ theme }) => theme.palette.primary.main};

  &:visited {
    color: ${({ theme }) => theme.palette.primary.light};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  &:active {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
