import { put, takeEvery } from 'redux-saga/effects';

import { getErrorMessage } from 'utils';
import * as actions from './index';

function* onFailureError({ payload }) {
  const errorMessage = getErrorMessage(payload);

  if (errorMessage !== '') {
    yield put(
      actions.addSnackbar({
        message: errorMessage,
        options: {
          preventDuplicate: true,
          variant: 'error',
        },
      }),
    );
  }
}

export default function* watchFailure() {
  yield takeEvery(action => /FAILURE/i.test(action.type), onFailureError);
}
