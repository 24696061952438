import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  resetSuccessMessage,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
} = users.actions;

export default users.reducer;
