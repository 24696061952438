import styled from 'styled-components';
import MuiContainer from '@material-ui/core/Container';

export const Container = styled(MuiContainer)`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    background-color: ${({ theme }) => theme.colors.mainBackground};
  }
`;
