import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const rules = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const {
  getRulesRequest,
  getRulesSuccess,
  getRulesFailure,
  setSelectedRulesRequest,
  setSelectedRulesSuccess,
  setSelectedRulesFailure,
} = rules.actions;

export default rules.reducer;
