import { eventTypes } from 'constants/index';
import { http } from '../index';

export const getPrescription = ({ id }) => {
  return http.get(`/device-management/v1/prescriptions/${id}`).then(({ data }) => {
    const pillEvents = new Map();
    const nonPillEvents = [];

    // remove duplicated pill events
    data.eventsMilli.forEach(event => {
      const { pillNumber } = event;

      if (event.type !== eventTypes.pillExtract) {
        nonPillEvents.push(event);
      } else if (
        !pillEvents.get(pillNumber) ||
        new Date(event.timestamp).getTime() > new Date(pillEvents.get(pillNumber).timestamp).getTime()
      ) {
        pillEvents.set(pillNumber, event);
      }
    });

    return {
      ...data,
      events: [...pillEvents.values(), ...nonPillEvents],
    };
  });
};
