import React from 'react';
import PropTypes from 'prop-types';
import AppBar from 'components/AppBar';
import Footer from 'components/Footer';
import Main from 'components/Main';
import GlobalLoader from 'components/Loader/GlobalLoader';
import Notifier from 'components/Notifier';
import * as S from './styled';

const Layout = ({ children }) => {
  return (
    <S.Container maxWidth={false} disableGutters>
      <AppBar />
      <Main>{children}</Main>
      <Footer />
      <GlobalLoader />
      <Notifier />
    </S.Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
