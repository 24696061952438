import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import notifications from './notifications';
import events from './events';
import prescription from './prescription';
import alerts from './alerts';
import rules from './rules';
import users from './users';
import actionsHistory from './actionsHistory';

const rootReducer = combineReducers({
  auth,
  notifications,
  events,
  prescription,
  alerts,
  rules,
  users,
  actionsHistory,
});

export default rootReducer;
