import { locationDisplayAccuracy } from 'constants/index';
import { DateTime } from 'luxon';

export * from './linkBulder';

export const mapCognitoUser = cognitoUser => {
  const idTokenPayload = cognitoUser.signInUserSession?.idToken?.payload;
  const tokenInfo = idTokenPayload && {
    'cognito:groups': idTokenPayload['cognito:groups'] || [],
  };

  return {
    attributes: cognitoUser.attributes,
    username: cognitoUser.username,
    ...tokenInfo,
  };
};

export const displayCellValue = value => {
  const noDataString = '';
  if (value === undefined || value === null) {
    return noDataString;
  }
  if (value instanceof Date) {
    return value.toGMTString();
  }
  if (typeof value === 'object') {
    if (Object.prototype.hasOwnProperty.call(value, 'lat') && Object.prototype.hasOwnProperty.call(value, 'lng')) {
      return value.lat === null || value.lng === null
        ? noDataString
        : `${Number(value.lat).toFixed(locationDisplayAccuracy)}, ${Number(value.lng).toFixed(
            locationDisplayAccuracy,
          )}`;
    }
    return value.toString ? value.toString() : String(value);
  }
  return value;
};

export const getErrorMessage = (error = {}) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error.response && error.response.data && (error.response.data.message || error.response.data.error)) {
    return error.response.data.message || error.response.data.error;
  }

  return error.message || '';
};

// eslint-disable-next-line no-console
export const log = msg => console.log(msg);

export const createAlert = (blister, date, rule) => {
  return {
    id: `${rule.name}_${
      blister.blisterPackId
    }_${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_${date.getHours()}`,
    blisterPackId: blister.blisterPackId,
    date: date.toUTCString(),
    message: rule.alert(DateTime.fromJSDate(date).toFormat('ccc, DD')),
  };
};

export const createSuccessSnackbar = (message, options) => {
  const basicOptions = {
    variant: 'success',
    preventDuplicate: true,
  };

  return {
    message,
    options: {
      ...basicOptions,
      ...options,
    },
  };
};

/**
 * Checks if the user has the specified role or one of them
 * @param roles Array or string
 * @param user Object with 'cognito:groups' property
 * @returns {*} boolean
 */
export const hasUserRole = (roles, user) => {
  const userRoles = user['cognito:groups'];

  if (Array.isArray(roles)) {
    return roles.some(role => userRoles.includes(role));
  }

  return userRoles.includes(roles);
};
