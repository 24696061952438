// staff for frontend rules implementation.
// Approach description: rules, checkers are hardcoded on the client.
// State of the rules are stored in browser localStorage
// import { takeEvery, put, select } from 'redux-saga/effects';
// import { getErrorMessage, log } from 'utils';
// import { addToStorage, getFromStorage } from 'utils/storage';
// import { getSerializableRules } from 'utils/rules';

// import { rules as basicRules } from 'models/Rule/rules';
// import * as actions from './index';

// function* onGetRules() {
//   try {
//     let rules;

//     const rulesFromStorage = getFromStorage('rules');
//     if (!rulesFromStorage || basicRules.version !== rulesFromStorage.version) {
//       addToStorage('rules', basicRules);
//       rules = getSerializableRules();
//     } else {
//       rules = rulesFromStorage;
//     }

//     yield put(actions.getRulesSuccess(rules));
//   } catch (error) {
//     log(getErrorMessage(error));
//     yield put(actions.getRulesFailure({ message: getErrorMessage(error) }));
//   }
// }

// function* onSetSelectedRules({ payload: rule }) {
//   try {
//     const rules = yield select(state => state.rules.data);
//     const version = yield select(state => state.rules.version) || basicRules.version;
//     const newRules = rules.map(r => {
//       if (r.name === rule.name) {
//         return rule;
//       }
//       return r;
//     });

//     const storeRules = { version, list: newRules };
//     addToStorage('rules', storeRules);

//     yield put(actions.getRulesSuccess(storeRules));
//   } catch (error) {
//     log(getErrorMessage(error));
//   }
// }

// staff for backend rules implementation.
// Aproach description: rules, checkers, state of the rules live on the server.
// Front just get list of the rules, show it on the appropriate page and
// send a PUT request to update rule state(activation and activation date)
import { takeEvery, put, call } from 'redux-saga/effects';
import { getErrorMessage, log } from 'utils';
import * as httpRules from 'http/rules';
import * as actions from './index';

function* onGetRules() {
  try {
    const rules = yield call(httpRules.getRules);

    yield put(actions.getRulesSuccess({ list: rules }));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getRulesFailure({ message: getErrorMessage(error) }));
  }
}

function* onSetSelectedRules({ payload: rule }) {
  try {
    yield call(httpRules.updateRulesState, rule);

    yield put(actions.setSelectedRulesSuccess());
    yield put(actions.getRulesRequest());
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.setSelectedRulesFailure({ message: getErrorMessage(error) }));
  }
}

const rulesSagas = [
  takeEvery(actions.getRulesRequest().type, onGetRules),
  takeEvery(actions.setSelectedRulesRequest().type, onSetSelectedRules),
];

export default rulesSagas;
