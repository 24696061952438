import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const users = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const { addSnackbar, removeSnackbar } = users.actions;

export default users.reducer;
