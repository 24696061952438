import styled from 'styled-components';
import PageContainerCommon from 'components/containers/PageContainer';
import LinkCommon from 'components/Link';

export const Footer = styled.footer`
  padding: ${({ theme }) => theme.spacing(3.75, 0)};
  box-shadow: 0 2px 40px 0 rgba(31, 93, 118, 0.04);
  background-color: ${({ theme }) => theme.colors.footerBackground};
`;

export const PageContainer = styled(PageContainerCommon)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 13px;
    line-height: 20px;
  }
`;

export const Link = styled(LinkCommon)`
  &:after {
    content: '•';
    margin: ${({ theme }) => theme.spacing(0, 3)};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const Copyright = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`;
