const initialState = {
  data: [],
  query: '',
  isLoading: false,
  error: '',
};

export const getActionsRequest = state => ({
  ...state,
  isLoading: true,
});

export const getActionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getActionsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export default initialState;
