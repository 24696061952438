import styled from 'styled-components';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiPopover from '@material-ui/core/Popover';
import LinkCommon from 'components/Link';

export const AuthMenuContainer = styled.div`
  margin-left: auto;
`;

export const Link = styled(LinkCommon)`
  color: ${({ theme }) => theme.palette.primary.contrastText} !important;
`;

export const AppBar = styled(MuiAppBar).attrs(() => ({
  elevation: 0,
}))`
  && {
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  }
`;

export const Popover = styled(MuiPopover).attrs(() => ({
  elevation: 0,
}))`
  && {
    .MuiPopover-paper {
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12);
    }
  }
`;
