import { call, takeLatest, takeEvery, put, select, debounce } from 'redux-saga/effects';
import { createSuccessSnackbar, getErrorMessage, log } from 'utils';

import { addSnackbar } from 'redux/notifications';
import * as httpEvents from 'http/events';
import * as actions from './index';

function* onGetEvents() {
  try {
    const { query } = yield select(state => state.events);
    const data = yield call(httpEvents.getEvents, { query });

    yield put(actions.getEventsSuccess(data));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getEventsFailure({ message: getErrorMessage(error) }));
  }
}

function* onSetEventsQuery() {
  yield put(actions.getEventsRequest());
}

function* onDeleteEvent({ payload: event }) {
  try {
    yield call(httpEvents.deleteEvent, event);

    yield put(actions.deleteEventSuccess());
    yield put(actions.getEventsRequest());
    yield put(addSnackbar(createSuccessSnackbar('Event deleted')));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.deleteEventFailure({ message: getErrorMessage(error) }));
  }
}

const eventsSagas = [
  takeLatest(actions.getEventsRequest().type, onGetEvents),
  debounce(300, actions.setSearchQuery().type, onSetEventsQuery),
  takeEvery(actions.deleteEventRequest().type, onDeleteEvent),
];

export default eventsSagas;
