/* eslint-disable camelcase */
import { createMuiTheme, unstable_createMuiStrictModeTheme, fade } from '@material-ui/core/styles';

// Supress console warning "findDOMNode is deprecated in StrictMode." for development
// https://github.com/mui-org/material-ui/issues/13394
// can be reworked after @material-ui/core updating up to version 5
const createTheme = process.env.NODE_ENV === 'production' ? createMuiTheme : unstable_createMuiStrictModeTheme;

const col0 = '#28A2D5'; // main
const col1 = '#2197CF'; // main
const col10 = '#F4F6F9'; // common bg
const col20 = '#E6EBF2'; // panel bg, input bg, border, delimiter, tabs indicator
const col30 = '#F7FBFE'; // hover bg,
const col31 = '#BDBDBD'; // light delimiter,
const col32 = '#DFECF4'; // button bg
const col33 = '#D9E8F2'; // button bg
const col34 = '#8B909D'; // switch bg
const col40 = '#444444'; // font,
const col41 = '#80878F'; // table header, tabs
const col42 = '#888888'; // table sorting
const col43 = '#5A6074'; // alerts list secondary text, switch thumb
const col50 = '#96A8C2'; // pagination arrows, page dropdown, search icon, +icons on white bg
// const col60 = '#50B054'; // insert circle, maybe success
const col70 = '#FFFFFF';

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1072,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: col0,
      dark: col1,
      contrastText: col70,
    },
    warning: {
      main: '#F57B02',
    },
    error: {
      main: '#F14949',
    },
    text: {
      primary: col40,
    },
    action: {
      disabledBackground: fade(col50, 0.2),
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const baseTheme = () => ({
  layout: {
    drawerWidth: '240px',
    input: {
      height: '72px',
      width: '180px',
    },
    tabs: {
      minWidth: '108px',
      indicator: {
        height: '4px',
      },
    },
    table: {
      pagination: {
        minHeight: '56px',
      },
    },
  },
  colors: {
    mainBackground: col10,
    footerBackground: col70,
    button: {
      color: col0,
      background: col32,
      hover: {
        background: col33,
      },
    },
    delimiter: {
      default: col20,
      light: col31,
    },
    input: {
      background: col20,
      focused: {
        background: col70,
      },
    },
    switch: {
      track: {
        background: col34,
      },
      thumb: {
        background: col43,
      },
    },
    icon: {
      background: col50,
    },
    tabs: {
      color: col41,
      indicator: {
        color: col20,
      },
    },
    table: {
      head: {
        color: col41,
      },
      body: {
        hover: col30,
      },
      sorting: {
        color: col42,
      },
      pagination: {
        actions: {
          color: col50,
        },
      },
    },
    alerts: {
      secondary: col43,
    },
    panel: {
      highlighted: {
        background: col20,
      },
    },
  },
  ...muiTheme,
});

export default baseTheme;
/* eslint-enable camelcase */
