import { takeEvery, put, call } from 'redux-saga/effects';
import { getErrorMessage, log } from 'utils';

import * as httpActions from 'http/actions';
import * as actions from './index';

function* onGetActions() {
  try {
    const data = yield call(httpActions.getActions);

    yield put(actions.getActionsSuccess(data));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getActionsFailure({ message: getErrorMessage(error) }));
  }
}

const actionsHistorySagas = [takeEvery(actions.getActionsRequest().type, onGetActions)];

export default actionsHistorySagas;
