import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader/index';

function GlobalLoader() {
  // using: check whatever isLoading you need
  // const show = useSelector(state => state.events.isLoading);
  const show = useSelector(state => !state);

  return <Loader show={show} />;
}

export default GlobalLoader;
