import axios from 'axios';
import { Auth } from 'aws-amplify';

const baseURL = process.env.REACT_APP_BASE_API;

export const http = axios.create({
  baseURL: baseURL || '/api',
});

http.interceptors.request.use(async config => {
  const currentSession = await Auth.currentSession().catch(() => {
    return null;
  });

  if (!currentSession) {
    window.location = '/auth';
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${currentSession.getIdToken().getJwtToken()}`;

  return config;
});
