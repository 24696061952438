import { getErrorMessage } from 'utils';

const initialState = {
  version: null,
  data: [],
  isLoading: false,
  error: '',
};

export const getRulesRequest = state => ({
  ...state,
  isLoading: true,
});

export const getRulesSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload.list,
  version: payload.version,
});

export const getRulesFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const setSelectedRulesRequest = state => ({
  ...state,
  isLoading: true,
});

export const setSelectedRulesSuccess = state => ({
  ...state,
  isLoading: false,
});

export const setSelectedRulesFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export default initialState;
