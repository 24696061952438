import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

// TODO:implement sizes: 'large' | 'medium' | 'small'
export const Button = styled(MuiButton).attrs(props => ({
  disableElevation: true,
  variant: props.variant || 'contained',
}))`
  && {
    background-color: ${({ color, theme }) => (color ? undefined : theme.colors.button.background)};
    color: ${({ color, theme }) => (color ? undefined : theme.colors.button.color)};

    line-height: 20px;
    padding: ${({ theme }) => theme.spacing(1.25, 3.375)};
    border-radius: ${({ theme }) => theme.spacing(0.5)}px;

    &:hover {
      background-color: ${({ color, theme }) => (color ? undefined : theme.colors.button.hover.background)};
    }
  }
`;
