import { http } from '../index';
// import { mock } from '../mock';
// import * as mocks from './mocks';

// IMPORTANT! Currently mocks are not in use. Go to rules/sagas.js and check comments there
// mock.onGet('/rules').reply(mocks.getRules);
// mock.onPut(/\/rules\/.+/).reply(mocks.updateRules);

export const getRules = () => {
  return http.get('/notification-management/v1/rules').then(({ data }) => data);
};

/**
 *
 * @param rule Object {
 *   activatedAt: Date | ISOString
 *   isActive: Boolean
 *   name: String
 * }
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateRulesState = rule => {
  const ruleData = {
    name: rule.name,
    activatedAt: rule.activatedAt,
    isActive: rule.isActive,
  };

  return http.put(`/notification-management/v1/rules/${rule.name}`, ruleData).then(({ data }) => data);
};
