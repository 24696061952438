import { createGlobalStyle } from 'styled-components';

export const AmplifyAuthStyles = createGlobalStyle`
  amplify-authenticator {
    --container-height: auto;
  }

  amplify-authenticator, amplify-sign-out {
    --amplify-font-family: ${({ theme }) => theme.typography.fontFamily};

    --amplify-primary-color: ${({ theme }) => theme.palette.primary.main};
    --amplify-primary-tint: ${({ theme }) => theme.palette.primary.light};
    --amplify-primary-shade: ${({ theme }) => theme.palette.primary.dark};

    --amplify-secondary-tint: ${({ theme }) => theme.palette.error.main};
  }
`;
