import React, { useMemo } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import baseTheme from 'theme';
// TODO:waiting server implementation
// temporary commented. SMBL-205
// On-the-fly renedering is implemented via Short polling approach
// import ServerWatcher from 'components/ServerWatcher';
import snackbarConfig from './config/snackbar';
import AppRouter from './router';
import './App.css';
import configureStore from './redux/store';

const store = configureStore();

function App() {
  const theme = useMemo(baseTheme, []);

  return (
    <StoreProvider store={store}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider {...snackbarConfig}>
            {/* <ServerWatcher /> */}
            <AppRouter />
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StoreProvider>
  );
}

export default App;
