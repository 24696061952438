import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const DisabledContainer = ({ children, className, position }) => (
  <S.Container className={className} position={position}>
    {children}
  </S.Container>
);

DisabledContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  position: PropTypes.oneOf(['absolute', 'fixed']),
};

DisabledContainer.defaultProps = {
  className: undefined,
  position: 'fixed',
};

export default DisabledContainer;
