import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const actionsHistory = createSlice({
  name: 'actionsHistory',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const { getActionsRequest, getActionsSuccess, getActionsFailure } = actionsHistory.actions;

export default actionsHistory.reducer;
