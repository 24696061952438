import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const useUnreviewedAlerts = () => {
  const { reviewedIds, data: alerts } = useSelector(state => state.alerts);

  const unreviewedAlerts = useMemo(() => {
    return alerts.filter(alert => !reviewedIds.includes(alert.id));
  }, [alerts, reviewedIds]);

  return { unreviewedAlerts, reviewedIds };
};

export default useUnreviewedAlerts;
