import { getErrorMessage } from 'utils';

const initialState = {
  events: [],
  query: '',
  isLoading: false,
  error: '',
};

export const getEventsRequest = state => ({
  ...state,
  isLoading: true,
});

export const getEventsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  events: payload,
});

export const getEventsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const setSearchQuery = (state, { payload }) => ({
  ...state,
  query: payload,
});

export const deleteEventRequest = state => ({
  ...state,
  isLoading: true,
});

export const deleteEventSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteEventFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export default initialState;
