import { all, fork } from 'redux-saga/effects';

import watchFailure from 'redux/notifications/sagas';
import eventsSagas from './events/sagas';
import alertsSagas from './alerts/sagas';
import rulesSagas from './rules/sagas';
import prescriptionSagas from './prescription/sagas';
import usersSagas from './users/sagas';
import actionsHistorySagas from './actionsHistory/sagas';

export default function* root() {
  yield all([
    ...eventsSagas,
    ...alertsSagas,
    ...rulesSagas,
    ...prescriptionSagas,
    ...usersSagas,
    ...actionsHistorySagas,
    fork(watchFailure),
  ]);
}
