import { http } from '../index';

// this variable is only present to simplify the process of transferring logic to the server
// it's better to cleanup all places where it's used after setting value to true
export const isServerUsed = true;

// There are a few steps to disable using of mocks and make code work with the server:
// 1. comment mock.onGet and mock.onDelete;
// 2. set isServerUsed to true. And it's better to cleanup
// 3. go to sagas, comment all necessary code for frontend approach and uncomment code for server aproach

// IMPORTANT! Currently mocks are not in use. Go to alerts/sagas.js and follow comments there

export const getAlerts = () => {
  return http.get('/notification-management/v1/alerts').then(({ data }) => data);
};

export const deleteAlert = ({ blisterPackId, timestamp }) => {
  const params = { blisterPackId, timestamp };
  return http.delete('/notification-management/v1/alerts', { params }).then(({ data }) => data);
};
