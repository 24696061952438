import { call, takeEvery, put } from 'redux-saga/effects';
import { getErrorMessage, log } from 'utils';

import * as httpPrescription from 'http/prescriptions';
import * as actions from './index';

function* onGetPrescription({ payload }) {
  try {
    const prescription = yield call(httpPrescription.getPrescription, { ...payload });

    yield put(actions.getPrescriptionSuccess(prescription));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getPrescriptionFailure({ message: getErrorMessage(error) }));
  }
}

const prescriptionSagas = [takeEvery(actions.getPrescriptionRequest().type, onGetPrescription)];

export default prescriptionSagas;
