import { getErrorMessage } from 'utils';

const initialState = {
  data: [],
  events: {
    data: [],
    isLoading: false,
  },
  reviewedIds: [],
  isLoading: false,
  error: '',
};

export const getAlertsRequest = state => ({
  ...state,
  isLoading: true,
});

export const getAlertsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getAlertsFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const getEventsRequest = state => ({
  ...state,
  events: {
    ...state.events,
    isLoading: true,
  },
});

export const getEventsSuccess = (state, { payload }) => ({
  ...state,
  events: {
    ...state.events,
    isLoading: false,
    data: payload,
  },
});

// this action is used for PoC version and probably should be deleted after implementation alerts logic on the server
export const setReviewedAlerts = (state, { payload }) => ({
  ...state,
  reviewedIds: payload,
});

export const deleteAlertRequest = state => ({
  ...state,
  isLoading: true,
});

export const deleteAlertSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteAlertFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export default initialState;
