import React from 'react';

import * as S from './styled';

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <S.Footer>
      <S.PageContainer>
        <S.Link to="/privacy">Privacy Policy</S.Link>
        <S.Link to="/terms">Terms of Conditions</S.Link>
        <S.Copyright>&copy; {year} Counted</S.Copyright>
      </S.PageContainer>
    </S.Footer>
  );
};

export default Footer;
