import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

const PageContainer = ({ children, className }) => (
  <Container maxWidth="md" className={className}>
    {children}
  </Container>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PageContainer.defaultProps = {
  className: undefined,
};

export default PageContainer;
