import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import DisabledContainer from 'components/containers/Disabled';

const Loader = ({ show, position, ...rest }) =>
  show ? (
    <DisabledContainer position={position}>
      <CircularProgress {...rest} />
    </DisabledContainer>
  ) : null;

Loader.propTypes = {
  show: PropTypes.bool,
  position: PropTypes.oneOf(['absolute', 'fixed']),
};

Loader.defaultProps = {
  show: false,
  position: 'fixed',
};

export default Loader;
