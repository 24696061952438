import styled from 'styled-components';
import ButtonCommon from 'components/Button';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiTypography from '@material-ui/core/Typography';

export const Button = styled(ButtonCommon)`
  && {
    margin-left: 8.82vw;
    opacity: 0;
    transition: opacity ${({ theme }) => theme.transitions.duration.shortest}ms;
  }
`;

export const List = styled(MuiList)`
  && {
    position: relative;
    padding-top: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const ListItem = styled(MuiListItem)`
  && {
    padding-left: ${({ theme }) => theme.spacing(3)}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.delimiter.default};
    &:hover {
      background-color: ${({ theme }) => theme.colors.table.body.hover};
      ${Button} {
        opacity: 1;
      }
    }
  }
`;

export const Primary = styled(MuiTypography).attrs(() => ({
  variant: 'body2',
}))`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(0.625)}px;
  }
`;

export const Secondary = styled(MuiTypography)`
  && {
    font-size: 13px;
    line-height: 20px;
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.alerts.secondary};
  }
`;
