import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const Link = ({ children, ...rest }) => <S.Link {...rest}>{children}</S.Link>;

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Link;
