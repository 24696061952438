import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const events = createSlice({
  name: 'prescription',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const { getPrescriptionRequest, getPrescriptionSuccess, getPrescriptionFailure } = events.actions;

export default events.reducer;
