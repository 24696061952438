// stuff for frontend alerts implementation.
// Approach description: alerts created on the client,
// reviewed(deleted) alerts are stored in the browser localStorage

// import { call, take, takeEvery, race, put, select, delay } from 'redux-saga/effects';
// import { getErrorMessage, log } from 'utils';
// import { rulesRunner } from 'utils/rules';
// import * as httpEvents from 'http/events';

// import { prescriptionsList as mockPrescriptionsList } from 'http/prescriptions/mockModel';
// import { addToStorage, getFromStorage } from 'utils/storage';
// import * as actions from './index';
// import * as rulesActions from '../rules';

// function* onGetAlerts() {
//   try {
//     const events = yield call(httpEvents.getEvents);
//     yield put(actions.getEventsSuccess(events));

//     // TODO:investigate is it possible to rewrite with all({ x1: call(), x2: delay(500, call()) })
//     // delay nesessary for lucky case, when rules are downloaded by Settings page
//     yield delay(500);

//     const rulesFromStore = yield select(state => state.rules);
//     let { data: rules } = rulesFromStore;
//     const { isLoading: rulesIsLoading, version: rulesVersion } = rulesFromStore;

//     if (!rulesVersion && !rulesIsLoading) {
//       yield put(rulesActions.getRulesRequest());
//       const { loadedRules, errorRules } = yield race({
//         loadedRules: take(rulesActions.getRulesSuccess().type),
//         errorRules: take(rulesActions.getRulesFailure().type),
//       });

//       if (loadedRules) {
//         rules = loadedRules.payload.list;
//       } else if (errorRules) {
//         throw new Error(errorRules.payload);
//       }
//     } else if (!rulesVersion && rulesIsLoading) {
//       const { loadedRules, errorRules } = yield race({
//         loadedRules: take(rulesActions.getRulesSuccess().type),
//         errorRules: take(rulesActions.getRulesFailure().type),
//       });

//       if (loadedRules) {
//         rules = loadedRules.payload.list;
//       } else if (errorRules) {
//         throw new Error(errorRules.payload);
//       }
//     }

//     // TODO:temporary solution
//     // TODO:decide and implement according to the backend.
//     // Should it be a separate request for every prescription or list or prescription data will be in event
//     // now prescription has only one blister so assumption is that blister is unique
//     const blistersEventsList = mockPrescriptionsList.map(prescription => {
//       const blisterPack = events.filter(e => e.blisterPackId === prescription.blisterPackIds[0]);
//       return { blisterPackId: prescription.blisterPackIds[0], events: blisterPack };
//     });

//     let alerts = rulesRunner(rules, blistersEventsList);
//     const storageAlerts = getFromStorage('alerts');
//     const storageReviewedAlertsIds = storageAlerts && storageAlerts.reviewed;
//     if (storageReviewedAlertsIds) {
//       yield put(actions.setReviewedAlerts(storageReviewedAlertsIds));
//     }
//     if (storageAlerts && storageAlerts.active) {
//       const uniqueIds = new Set();
//       alerts = alerts.concat(storageAlerts.active).filter(alert => {
//         if (uniqueIds.has(alert.id)) {
//           return false;
//         }
//         uniqueIds.add(alert.id);
//         return true;
//       });
//     }

//     const { reviewedIds: reviewedAlertsIds } = yield select(state => state.alerts);
//     addToStorage('alerts', {
//       reviewed: reviewedAlertsIds,
//       active: alerts.filter(a => !reviewedAlertsIds.includes(a.id)),
//     });

//     yield put(actions.getAlertsSuccess(alerts));
//   } catch (error) {
//     log(getErrorMessage(error));
//     yield put(actions.getAlertsFailure({ message: getErrorMessage(error) }));
//   }
// }

// const alertsSagas = [
//   takeEvery(actions.getAlertsRequest().type, onGetAlerts),
//   takeEvery(rulesActions.setSelectedRulesRequest().type, onGetAlerts),
// ];

// stuff for backend alerts implementation.
// Approach description: alerts created and stored on the server,
// client gets alerts list, sends request via DELETE method to hide particular alert on the client

import { call, takeEvery, put } from 'redux-saga/effects';
import { getErrorMessage, log } from 'utils';

import * as httpAlerts from 'http/alerts';
import * as actions from './index';
import * as rulesActions from '../rules';

function* onGetAlerts() {
  try {
    const alerts = yield call(httpAlerts.getAlerts);

    yield put(actions.getAlertsSuccess(alerts));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getAlertsFailure({ message: getErrorMessage(error) }));
  }
}

function* onDeleteAlert({ payload: alert }) {
  try {
    yield call(httpAlerts.deleteAlert, alert);

    yield put(actions.deleteAlertSuccess());
    yield put(actions.getAlertsRequest());
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getAlertsFailure({ message: getErrorMessage(error) }));
  }
}

const alertsSagas = [
  takeEvery(actions.getAlertsRequest().type, onGetAlerts),
  takeEvery(rulesActions.setSelectedRulesRequest().type, onGetAlerts),
  takeEvery(actions.deleteAlertRequest().type, onDeleteAlert),
];

export default alertsSagas;
