import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const alerts = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const {
  getAlertsRequest,
  getAlertsSuccess,
  getAlertsFailure,
  getEventsRequest,
  getEventsSuccess,
  setReviewedAlerts,
  deleteAlertRequest,
  deleteAlertSuccess,
  deleteAlertFailure,
} = alerts.actions;

export default alerts.reducer;
