import { useEffect, useState } from 'react';

const baseMainInterval = Number.isNaN(Number(process.env.REACT_APP_SHORT_POLL_INTERVAL))
  ? 5
  : Number(process.env.REACT_APP_SHORT_POLL_INTERVAL);
const config = {
  mainInterval: baseMainInterval * 60 * 1000,
  minMainInterval: 20 * 1000,
  forceInterval: 20 * 1000,
  minForceInterval: 5 * 1000,
};

const useShortPollTimer = (params = {}) => {
  let { mainInterval = config.mainInterval, forceInterval = config.forceInterval } = params;
  if (mainInterval < config.minMainInterval) {
    mainInterval = config.minMainInterval;
  }
  if (forceInterval < config.minForceInterval) {
    forceInterval = config.minForceInterval;
  }

  const [mainTimer, setShortPollTimer] = useState();
  const [lastTime, setLastTime] = useState(() => new Date().getTime());

  useEffect(() => {
    const forceTimer = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime - lastTime > mainInterval + 3000) {
        setShortPollTimer(forceTimer);
        setLastTime(currentTime);
      }
    }, forceInterval);

    const timer = setTimeout(() => {
      clearInterval(forceTimer);
      setShortPollTimer(timer);
      setLastTime(new Date().getTime());
    }, mainInterval);

    return () => {
      clearTimeout(timer);
      clearInterval(forceTimer);
    };
  }, [setShortPollTimer, mainInterval, forceInterval, lastTime]);

  return { shortPollTimer: mainTimer };
};

export default useShortPollTimer;
