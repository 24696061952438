import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import useAuthenticator from 'hooks/useAuthenticator';
import Layout from 'components/Layout';
import PrivateRoute from './PrivateRoute';

const Auth = lazy(() => import('pages/Auth'));
const Home = lazy(() => import('pages/Home'));
const Settings = lazy(() => import('pages/Settings'));
const Prescription = lazy(() => import('pages/Prescription'));
const Privacy = lazy(() => import('pages/Privacy'));
const TermsOfConditions = lazy(() => import('pages/TermsOfConditions'));
const NotFound = lazy(() => import('pages/NotFound'));

const AppRouter = () => {
  useAuthenticator();

  return (
    <Router>
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute exact path="/prescription/:id" component={Prescription} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={TermsOfConditions} />
            <Route path="/auth" component={Auth} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
};

export default AppRouter;
