const getDateInSeconds = date => Math.floor(date.valueOf() / 1000);

/**
 *
 * @param time
 * @returns {number} returns timeshift to the closest available time according to the parameter
 */
const getTimeShift = time => {
  const currentDate = new Date();
  const timerDate = new Date();
  timerDate.setHours(...time.split(','));

  while (getDateInSeconds(timerDate) - getDateInSeconds(currentDate) <= 0) {
    timerDate.setDate(timerDate.getDate() + 1);
  }

  return timerDate - currentDate;
};

let timer;

export const setTimer = (time, cb) => {
  const timeShift = getTimeShift(time);
  timer = setTimeout(() => {
    cb();
    setTimer(time, cb);
  }, timeShift);

  return () => clearTimeout(timer);
};
