import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const { authenticate, signOut } = auth.actions;

export default auth.reducer;
