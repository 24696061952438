import { getErrorMessage } from 'utils';

const initialState = {
  data: {},
  isLoading: false,
  error: '',
};

export const getPrescriptionRequest = state => ({
  ...state,
  isLoading: true,
});

export const getPrescriptionSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getPrescriptionFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export default initialState;
