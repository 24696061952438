import { takeEvery, put, call } from 'redux-saga/effects';
import { getErrorMessage, createSuccessSnackbar, log } from 'utils';

import { addSnackbar } from 'redux/notifications';
import * as httpUsers from 'http/users';
import * as actions from './index';

function* onGetUsers() {
  try {
    const users = yield call(httpUsers.getUsers);

    yield put(actions.getUsersSuccess(users));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getUsersFailure({ message: getErrorMessage(error) }));
  }
}

function* onCreateUser({ payload }) {
  try {
    const res = yield call(httpUsers.createUser, payload);

    yield put(actions.createUserSuccess(res));
    yield put(addSnackbar(createSuccessSnackbar('Invitation sent')));
    yield put(actions.getUsersRequest());
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.getUsersFailure({ message: getErrorMessage(error) }));
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    yield call(httpUsers.deleteUser, user);

    yield put(actions.deleteUserSuccess());
    yield put(actions.getUsersRequest());
    yield put(addSnackbar(createSuccessSnackbar('User deleted')));
  } catch (error) {
    log(getErrorMessage(error));
    yield put(actions.deleteUserFailure({ message: getErrorMessage(error) }));
  }
}

const usersSagas = [
  takeEvery(actions.getUsersRequest().type, onGetUsers),
  takeEvery(actions.createUserRequest().type, onCreateUser),
  takeEvery(actions.deleteUserRequest().type, onDeleteUser),
];

export default usersSagas;
