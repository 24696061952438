import React from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { AmplifyAuthStyles } from '../styled';

const SignOut = () => (
  <>
    <AmplifyAuthStyles />
    <AmplifySignOut />
  </>
);

export default SignOut;
