import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const IconButton = React.forwardRef(({ children, ...rest }, ref) => (
  <S.IconButton {...rest} ref={ref}>
    {children}
  </S.IconButton>
));

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconButton;
