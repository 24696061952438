import { http } from '../index';
// import { mock } from '../mock';
// import * as mocks from './mocks';

// mock.onGet('/users').reply(mocks.getUsers);
// mock.onPost('/users').reply(mocks.createUser);

export const getUsers = () => {
  return http.get('/user-management/users').then(({ data }) => data);
};

export const createUser = user => {
  return http.post('/user-management/users', user).then(({ data }) => data);
};

export const deleteUser = ({ id }) => {
  return http.delete(`/user-management/users/${id}`).then(({ data }) => data);
};
