import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Loader from 'components/Loader';

import { isServerUsed } from 'http/alerts'; // temporary solution, see comment in the source file
import { deleteAlertRequest } from 'redux/alerts';
import useUnreviewedAlerts from 'hooks/useUnreviewedAlerts';
import { addToStorage } from 'utils/storage';
import * as S from './styled';

const MenuList = React.forwardRef(({ isLoading, ...rest }, ref) => {
  const dispatch = useDispatch();

  const { unreviewedAlerts: filteredAlerts, reviewedIds: reviewedAlerts } = useUnreviewedAlerts();

  const handleMarkAsReviewed = useCallback(
    alert => {
      dispatch(deleteAlertRequest(alert));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isServerUsed) {
      addToStorage('alerts', { reviewed: reviewedAlerts, active: filteredAlerts });
    }
  }, [reviewedAlerts, filteredAlerts]);

  return (
    <S.List ref={ref} {...rest}>
      <Loader show={isLoading} position="absolute" />
      {filteredAlerts.length ? (
        filteredAlerts.map(alert => (
          <S.ListItem key={alert.blisterPackId + alert.timestamp}>
            <ListItemText
              disableTypography
              primary={<S.Primary>{alert.message}</S.Primary>}
              secondary={<S.Secondary>Prescription Id: {alert.blisterPackId}</S.Secondary>}
            />
            <S.Button onClick={() => handleMarkAsReviewed(alert)} aria-label="Mark as reviewed">
              Ok
            </S.Button>
          </S.ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No alerts" />
        </ListItem>
      )}
    </S.List>
  );
});

MenuList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default MenuList;
