import { createSlice } from '@reduxjs/toolkit';
import initialState, * as handlers from './handlers';

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    ...handlers,
  },
});

export const {
  getEventsRequest,
  getEventsSuccess,
  getEventsFailure,
  setSearchQuery,
  deleteEventRequest,
  deleteEventSuccess,
  deleteEventFailure,
} = events.actions;

export default events.reducer;
