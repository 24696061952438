import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
// import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import useTimer from 'hooks/useTimer';
import useUnreviewedAlerts from 'hooks/useUnreviewedAlerts';
import useShortPollTimer from 'hooks/useShortPollTimer';
import { getAlertsRequest } from 'redux/alerts';
import PageContainer from 'components/containers/PageContainer';
import Link from 'components/Link';
import Alerts from 'components/Alerts/MenuList';
import AmplifySignOut from 'components/AmplifyAuth/SignOut';
import IconButton from 'components/IconButton';
import Logo from './Logo';
import * as S from './styled';

const AppBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const { unreviewedAlerts } = useUnreviewedAlerts();
  const isLoadingAlerts = useSelector(state => state.alerts.isLoading);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [alertsAnchorEl, setAlertsAnchorEl] = useState(null);
  const isUserMenuOpen = useMemo(() => Boolean(userAnchorEl), [userAnchorEl]);
  const isAlertsMenuOpen = useMemo(() => Boolean(alertsAnchorEl), [alertsAnchorEl]);

  const alertsTimerCallback = useCallback(() => dispatch(getAlertsRequest()), [dispatch]);
  useTimer('0,0,1', alertsTimerCallback);

  const handleUserMenu = useCallback(
    event => {
      setUserAnchorEl(event.currentTarget);
    },
    [setUserAnchorEl],
  );

  const handleAlertsMenu = useCallback(
    event => {
      setAlertsAnchorEl(event.currentTarget);
    },
    [setAlertsAnchorEl],
  );

  const handleUserMenuClose = useCallback(() => {
    setUserAnchorEl(null);
  }, [setUserAnchorEl]);

  const handleAlertsMenuClose = useCallback(() => {
    setAlertsAnchorEl(null);
  }, [setAlertsAnchorEl]);

  const goTo = useCallback(
    url => {
      history.push(url);
      handleUserMenuClose();
    },
    [handleUserMenuClose, history],
  );

  useEffect(() => {
    if (!isAuthenticated) {
      handleUserMenuClose();
    }
  }, [handleUserMenuClose, isAuthenticated]);

  const { shortPollTimer } = useShortPollTimer();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAlertsRequest());
    }
  }, [dispatch, isAuthenticated, shortPollTimer]);

  return (
    <S.AppBar position="sticky">
      <PageContainer>
        <Toolbar disableGutters>
          {/* TODO: add something like Drawer handled by MenuIcon */}
          {/*
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            */}
          <Link to="/">
            <Logo />
          </Link>
          <S.AuthMenuContainer>
            {isAuthenticated ? (
              <>
                <Tooltip title="Alerts">
                  <IconButton
                    aria-label={`${unreviewedAlerts.length} alerts`}
                    aria-controls="menu-alerts"
                    aria-haspopup="true"
                    onClick={handleAlertsMenu}
                    color="inherit"
                  >
                    <Badge badgeContent={unreviewedAlerts.length} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="User menu">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleUserMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
                <S.Popover
                  id="menu-alerts"
                  anchorEl={alertsAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={isAlertsMenuOpen}
                  onClose={handleAlertsMenuClose}
                >
                  <Alerts isLoading={isLoadingAlerts} />
                </S.Popover>
                <Menu
                  id="menu-appbar"
                  anchorEl={userAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={isUserMenuOpen}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem onClick={() => goTo('/settings')}>Settings</MenuItem>
                  <AmplifySignOut />
                </Menu>
              </>
            ) : (
              <S.Link to="/auth">Sign in</S.Link>
            )}
          </S.AuthMenuContainer>
        </Toolbar>
      </PageContainer>
    </S.AppBar>
  );
};

export default AppBar;
