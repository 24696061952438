import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const Main = ({ children }) => <S.PageContainer>{children}</S.PageContainer>;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
