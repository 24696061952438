import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Amplify from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from 'config/aws-exports';
import { mapCognitoUser } from 'utils';
import { authenticate, signOut } from 'redux/auth';

Amplify.configure(awsconfig);

function useAuthenticator() {
  const dispatch = useDispatch();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn) {
        dispatch(authenticate(mapCognitoUser(authData)));
      } else if (nextAuthState === AuthState.SignedOut) {
        dispatch(signOut());
      }
    });
  }, [dispatch]);
}

export default useAuthenticator;
