import { useEffect } from 'react';
import { setTimer } from 'utils/timer';

/**
 *
 * @param time format: 'h,m,s' | 'hh,mm,ss'
 * @param cb
 */
const useTimer = (time, cb) => {
  useEffect(() => {
    return setTimer(time, cb);
  }, [time, cb]);
};

export default useTimer;
