import styled from 'styled-components';
import PageContainerCommon from 'components/containers/PageContainer';

export const PageContainer = styled(PageContainerCommon)`
  && {
    flex-grow: 1;
    padding-top: ${({ theme }) => theme.spacing(3)}px;
    padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`;
