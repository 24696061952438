import { getErrorMessage } from 'utils';

const initialState = {
  data: [],
  isLoading: false,
  error: '',
  successMessage: '',
};

export const getUsersRequest = state => ({
  ...state,
  isLoading: true,
});

export const getUsersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  data: payload,
});

export const getUsersFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export const createUserRequest = state => ({
  ...state,
  isLoading: true,
});

export const createUserSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  successMessage: payload,
});

export const createUserFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export const resetSuccessMessage = state => ({
  ...state,
  successMessage: initialState.successMessage,
});

export const deleteUserRequest = state => ({
  ...state,
  isLoading: true,
});

export const deleteUserSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteUserFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: getErrorMessage(payload),
});

export default initialState;
