import styled from 'styled-components';

export const Container = styled.div`
  position: ${({ position }) => position};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.palette.action.disabledBackground};
  z-index: ${({ theme, position }) => (position === 'fixed' ? theme.zIndex.tooltip : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;
