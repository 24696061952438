import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

const configure = initialStore => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  const store = configureStore({
    middleware: [...getDefaultMiddleware({ thunk: false }), ...middlewares],
    preloadedState: initialStore,
    reducer: rootReducer,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configure;
