const initialState = {
  notifications: [],
};

export const addSnackbar = (state, { payload }) => ({
  ...state,
  notifications: [...state.notifications, { ...payload, key: new Date().getTime() + Math.random() }],
});

export const removeSnackbar = (state, { payload }) => ({
  ...state,
  notifications: state.notifications.filter(notification => notification.key !== payload),
});

export default initialState;
